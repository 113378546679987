<template>
  <v-container class="app-page">
    <v-card v-if="perfil" class="pa-6 mb-6 form-dense">
      <h1 class="titulo">Datos Personales</h1>
      <v-row>
        <v-col class="d-flex" cols="6" xs="6" sm="6">
          <v-text-field label="1er Nombre" v-model="perfil.Nombre_1" readonly dense></v-text-field>
        </v-col>
        <v-col class="d-flex" cols="6" xs="6" sm="6">
          <v-text-field label="2do Nombre" v-model="perfil.Nombre_2" readonly dense></v-text-field>
        </v-col>
        <v-col class="d-flex" cols="6" xs="6" sm="6">
          <v-text-field label="1er Apellido" v-model="perfil.Apellido_1" readonly dense></v-text-field>
        </v-col>
        <v-col class="d-flex" cols="6" xs="6" sm="6">
          <v-text-field label="2do Apellido" v-model="perfil.Apellido_2" readonly dense></v-text-field>
        </v-col>
        <v-col class="d-flex" cols="6" xs="6" sm="6">
          <v-text-field label="Nacionalidad" v-model="nacionalidad" readonly dense></v-text-field>
        </v-col>
        <v-col class="d-flex" cols="6" xs="6" sm="6">
          <v-text-field label="Departamento" v-model="departamento" readonly dense></v-text-field>
        </v-col>
        <v-col class="d-flex" cols="6" xs="6" sm="6">
          <v-text-field label="Dirección" v-model="perfil.Direccion" readonly dense></v-text-field>
        </v-col>
        <v-col class="d-flex" cols="6" xs="6" sm="6">
          <v-text-field label="Correo electrónico" v-model="perfil.email" readonly dense></v-text-field>
        </v-col>
        <v-col class="d-flex" cols="6" xs="6" sm="6">
          <v-text-field label="Teléfono" v-model="perfil.TelCel" readonly dense></v-text-field>
        </v-col>
        <v-col class="d-flex" cols="6" xs="6" sm="6">
          <v-text-field label="Estado Civil" v-model="estadoCivil" readonly dense></v-text-field>
        </v-col>
      </v-row>
    </v-card>

    <v-card v-if="perfil" class="pa-6 mb-6 form-dense">
      <h1 class="titulo">Actividad</h1>
      <v-row>
        <v-col class="d-flex" cols="12" xs="6" sm="6">
          <v-select 
            v-model="currentContrato.Id_Contrato"
            @change="selectCurrent"
            :items="contratos" 
            :item-text="item => item.NA + ' ->' + item.Cargo"
            item-value="Id_Contrato"
            placeholder="Seleccione el contrato" 
            label="Nº de Contrato" dense>
          </v-select>
        </v-col>
        <v-col class="d-flex" cols="6" xs="6" sm="6">
          <v-text-field label="Tipo Contrato" v-model="currentContrato.NomTContrato" readonly dense></v-text-field>
        </v-col>
        <v-col class="d-flex" cols="6" xs="6" sm="6">
          <v-text-field label="Fecha Inicio" v-model="currentContrato.FecIni" readonly dense></v-text-field>
        </v-col>
        <v-col class="d-flex" cols="6" xs="6" sm="6">
          <v-text-field label="Fecha Egreso" value="--/--/----" readonly dense></v-text-field>
        </v-col>

        <v-col class="d-flex my-2" cols="12" xs="12" sm="12"></v-col>

        <v-col class="d-flex" cols="6" xs="6" sm="6">
          <v-text-field label="Cargo" v-model="currentContrato.Cargo" readonly dense></v-text-field>
        </v-col>
        <v-col class="d-flex" cols="6" xs="6" sm="6">
          <v-text-field label="Sucursal" v-model="currentContrato.Sucursal" readonly dense></v-text-field>
        </v-col>
        <v-col class="d-flex" cols="6" xs="6" sm="6">
          <v-text-field label="Sector" v-model="currentContrato.Sector" readonly dense></v-text-field>
        </v-col>
        <v-col class="d-flex" cols="6" xs="6" sm="6">
          <v-text-field
            label="Régimen Horario"
            v-model="currentContrato.RegHorario"
            readonly
            dense
          ></v-text-field>
        </v-col>

        <v-col class="d-flex my-2" cols="12" xs="12" sm="12"></v-col>

        <v-col class="d-flex" cols="6" xs="6" sm="6">
          <v-text-field label="Tipo Remuneración" v-model="currentContrato.TipoRem" readonly dense></v-text-field>
        </v-col>
        <v-col class="d-flex" cols="6" xs="6" sm="6">
          <v-text-field label="Sueldo" v-model="currentContrato.SueldoCobraX" readonly dense></v-text-field>
        </v-col>

        <v-col class="d-flex my-2" cols="12" xs="12" sm="12"></v-col>

        <v-col class="d-flex" cols="6" xs="6" sm="6">
          <v-text-field label="Vínculo Funcional" v-model="currentContrato.idVF" readonly dense></v-text-field>
        </v-col>
        <v-col class="d-flex" cols="6" xs="6" sm="6">
          <v-text-field label="Seguro de Salud" v-model="currentContrato.idSS" readonly dense></v-text-field>
        </v-col>
        <v-col class="d-flex" cols="6" xs="6" sm="6">
          <v-text-field label="Código de Exoneración" v-model="currentContrato.idEX" readonly dense></v-text-field>
        </v-col>
        <v-col class="d-flex" cols="6" xs="6" sm="6">
          <v-text-field label="Cómputo Especial" v-model="currentContrato.idCE" readonly dense></v-text-field>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { getPerfilActividad } from "@/api/core";

export default {
  name: "personal",
  data() {
    return {
      perfil: null,
      currentContrato: {
        Id_Contrato: null,
        Cargo: null,
        Sucursal: null,
        Sector: null,
        FecIni: null,
        NomTContrato: null,
        TipoRem: null,
        SueldoCobraX: null,
        RegHorario: null,
        idVF: null,
        idSS: null,
        idEX: null,
        idCE: null
      },
      contratos: []
    };
  },

  created() {},

  watch: {},

  computed: {
    ...mapGetters(["user", "collections"]),

    estadoCivil() {
      if(this.perfil) {
        return this.getEstadoCivil(this.perfil.ID_EstadoCivil)
      }

      return "Sin Dato"
    },

    departamento() {
      if(this.perfil) {
        return this.getDepartamento(this.perfil.Departamento)
      }

      return "Sin Dato"
    },

    nacionalidad() {
      if(this.perfil) {
        return this.getNacionalidad(this.perfil.id_Nacionalidad)
      }

      return "Sin Dato"
    }
  },

  mounted() {
    this.$store.commit("startProcessing", {
      value: true,
      message: "Cargando...",
    })
    this.$store.commit("setNavigationDrawer", false)
    this.$store.commit("setBackButton", false)
    this.$store.commit("setToolbarTitle", "Datos Personales y Actividades")
    this.getUser()
  },

  methods: {
    async getUser() {
      const data = await getPerfilActividad();
      this.perfil = data
      this.contratos = Object.values(this.perfil.lstContratosAct)
      if(this.contratos.length == 1) {
        this.selectCurrent(this.contratos[0].Id_Contrato)
      }
      this.$store.commit("stopProcessing")
    },

    selectCurrent(item_id) {
      Object.assign(this.currentContrato, this.contratos.find(element => element.Id_Contrato == item_id))
    },

    getEstadoCivil(id) {
      let record = this.collections.estado_civil.find(element => element.id == id)
      return record ? record.value : "Sin Dato"
    },

    getDepartamento(id) {
      let record = this.collections.departamentos.find(element => element.id == id)
      return record ? record.value : "Sin Dato"
    },

    getNacionalidad(id) {
      let record = this.collections.nacionalidades.find(element => element.id == id)
      return record ? record.value : "Sin Dato"
    }
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.btn-rd {
  background: #ff7f04;
  border-radius: 20px;
  width: 230px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: none;
}
</style>

<style>
.form-dense .row > div {
  padding-top: 0.3rem !important;
  padding-bottom: 0.3rem !important;
}
</style>
